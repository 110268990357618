<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('add_subaccountlist_shop')">分账商户信息</span>
      <span @click="toUrl('add_subaccountlist_electron')"  v-if="detail.mchType != 'PERSONAL' && tabData.authStatus == 'AUTH_SUCCESSED' && tabData.approveStatus == 'PASSED'">电子账簿信息</span>
    </div>
    <div v-auth="'ACCOUNT:ALLOC:MERCHNATS:BASE'">
      <div class="desc">
        <h5 class="jsxx float_left">基本信息</h5>
        <el-button
          v-auth="'ACCOUNT:ALLOC:MERCHNATS:BASE/EDIT'"
          @click="toUrl('add_subaccountlist_edit')"
          class="editBt"
          type="primary"
          >编辑</el-button
        >
      </div>
      <div class="list clear">
        <ul class="listBox">
          <li>
            <span>分账商户全称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.allocMchName"
                placement="top"
              >
                <span>{{ detail.allocMchName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>分账商户编号</span>
            <i class="hidden1">{{ detail.allocMchId }}</i>
          </li>
          <li>
            <span>所属商户</span>
            <i class="hidden1">{{ detail.mchName }}</i>
          </li>
          <li>
            <span>分账商户简称</span>
            <i class="hidden1">
              {{ detail.mchShortName ? detail.mchShortName : "" }}
            </i>
          </li>
          <li>
            <span>分账商户登录名</span>
            <i class="hidden1">{{ detail.loginName }}</i>
          </li>
          <li>
            <span>分账商户类型</span>
            <i class="hidden1">{{ mchType[detail.mchType] }}</i>
          </li>
          <li>
            <span>联系人</span>
            <i class="hidden1">{{ detail.contactName }}</i>
          </li>
          <li>
            <span>联系人手机</span>
            <i class="hidden1">{{ detail.contactTel }}</i>
          </li>
          <li>
            <span>法人姓名</span>
            <i class="hidden1">{{ detail.legalPersonName }}</i>
          </li>
          <li>
            <span>法人身份证号码</span>
            <i class="hidden1">{{ detail.legalPersonNo }}</i>
          </li>          
          <li>
            <span>法人手机号码</span>
            <i class="hidden1">{{ detail.legalPersonPhone }}</i>
          </li>
          <li>
            <span>法人证件有效期</span>
            <i class="hidden1"
              v-if="
                !detail.legalPersonCemStartDate && !detail.legalPersonCemEndDate
              "
            ></i>
            <i v-else class="hidden1">
              {{ detail.legalPersonCemStartDate
              }}<span
                v-if="
                  detail.legalPersonCemStartDate && detail.legalPersonCemEndDate
                "
                >至</span
              >{{
                detail.legalPersonCemEndDate == "forever"
                  ? "长期"
                  : detail.legalPersonCemEndDate
              }}
            </i>
          </li>
          <li>
            <span>统一社会信用代码</span>
            <i class="hidden1">
              {{ detail.licenceNo ? detail.licenceNo : "" }}
            </i>
          </li>
          <li>
            <span>营业执照有效期</span>
            <i class="hidden1" v-if="!detail.licenceStartDate && !detail.licenceEndDate"></i>
            <i v-else class="hidden1">
              {{ detail.licenceStartDate
              }}<span v-if="detail.licenceStartDate && detail.licenceEndDate"
                >至</span
              >{{
                detail.licenceEndDate == "forever"
                  ? "长期"
                  : detail.licenceEndDate
              }}
            </i>
          </li>
          <li>
            <span>经营范围</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.businessScope"
                placement="top"
              >
                <span>{{ detail.businessScope }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>营业地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.businessAddress"
                placement="top"
              >
                <span>{{ detail.businessAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>异步通知地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.notifyUrl"
                placement="top"
              >
                <span>{{ detail.notifyUrl }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>营业执照</span>
            <i class="hidden1">
              <a
                v-if="detail.licencePicture"
                href="javascript:;"
                @click="showImg(detail.licencePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人证件正面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javascript:;"
                @click="showImg(detail.idcardNormalPicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人证件反面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javascript:;"
                @click="showImg(detail.idcardReversePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>开户许可证</span>
            <i class="hidden1">
              <a
                v-if="detail.accountOpeningLicense"
                href="javascript:;"
                @click="showImg(detail.accountOpeningLicense)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>其他类型图片1</span>
            <i class="hidden1">
              <a
                v-if="detail.otherPicture1"
                href="javascript:;"
                @click="showImg(detail.otherPicture1)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>其他类型图片2</span>
            <i class="hidden1">
              <a
                v-if="detail.otherPicture2"
                href="javascript:;"
                @click="showImg(detail.otherPicture2)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>创建时间</span>
            <i class="hidden1">{{ detail.createTime }}</i>
          </li>
          <li>
            <span>审核时间</span>
            <i class="hidden1">
              {{ detail.checkTime ? detail.checkTime : "" }}
            </i>
          </li>
          <li>
            <span>最后修改时间</span>
            <i class="hidden1">{{ detail.updateTime }}</i>
          </li>
          <li>
            <span>最后修改人</span>
            <i class="hidden1">{{ detail.lastUpdatedUser }}</i>
          </li>
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">结算信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 171px">
              <p>账户名</p>
            </td>
            <td style="width: 163px">
              <p>账号</p>
            </td>
            <td style="width: 95px">
              <p>联行号</p>
            </td>
            <td style="width: 269px">
              <p>账户类型</p>
            </td>
          </tr>
          <tr v-for="(v, i) in settlementsTabData" :key="i">
            <td style="width: 171px">
              <p>{{ v.accountName }}</p>
            </td>
            <td style="width: 163px">
              <p>{{ v.accountNo }}</p>
            </td>
            <td style="width: 95px">
              <p>{{ v.interBranchesNo }}</p>
            </td>
            <td style="width: 269px">
              <p>{{ accountType[v.accountType] }}</p>
            </td>
          </tr>
        </table>
      </div>
      <div class="desc">
        <h5 class="jsxx">结算周期</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>结算方式</p>
            </td>
            <td>
              <p>结算周期类型</p>
            </td>
            <td>
              <p>结算周期</p>
            </td>
          </tr>
          <tr v-for="(v, i) in productsTabData" :key="i">
            <td style="width: 171px">
              <p>{{ settleType[v.settleType] }}</p>
            </td>
            <td style="width: 163px">
              <p>{{ cycleType[v.cycleType] }}</p>
            </td>
            <td style="width: 187px">
              <p>{{ v.settleDay }}</p>
            </td>
          </tr>
        </table>
      </div>
      <el-dialog title="分账商户信息" :visible.sync="newShopShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title">商户状态</div>
            <div>
              <span class="bt">{{ mchStatus[tabForm.mchStatus] }}</span
              ><span
                class="lianjie"
                @click="cloneStatus(tabForm.thirdAccountId, 'MCH')"
                >同步</span
              >
            </div>
            <div class="overAccount_ul_title">签约状态</div>
            <div>
              <span class="bt">{{ signStatus[tabForm.signStatus] }}</span
              ><span
                class="lianjie"
                @click="cloneStatus(tabForm.thirdAccountId, 'SIGN')"
                >同步</span
              ><span class="lianjie" @click="setSign(tabForm.thirdAccountId)"
                >签约</span
              ><span class="lianjie" @click="setSignC(tabForm.thirdAccountId)"
                >协议内容</span
              >
            </div>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title">认证状态</div>
            <div>
              <span class="bt">{{ authStatus[tabForm.authStatus] }}</span>
            </div>
            <div class="overAccount_ul_title">图片审核状态</div>
            <div>
              <span class="bt">{{ approveStatus[tabForm.approveStatus] }}</span
              ><span
                class="lianjie"
                @click="cloneStatus(tabForm.thirdAccountId, 'APPROVE')"
                >同步</span
              >
            </div>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="newShopShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="newShopShow = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
        <a :href="showImgUrl" target="_blank">
          <img :src="showImgUrl" alt="加载失败" />
        </a>
      </div>
      <div class="cover_view" v-if="signHtml !== ''" @click="signHtml = ''">
        <div v-html="signHtml" class="html"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getAllocmerchantsDetail,
  getThirdaccounts,
  setThirdaccountsSign,
  setThirdaccountsSignC,
} from "@/api/user/subaccount.js";
export default {
  data() {
    return {
      newShopShow: false, //新增商户信息展示
      detail: {}, //详情
      mchType: {
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
        PERSONAL: "个人",
      },
      showImgUrl: "",
      tabData: [],
      mchStatus: {
        null: "",
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      authStatus: {
        null: "",
        UNAUTHORIZED: "未认证",
        AUTHENTICATING: "认证中",
        AUTH_FAILED: "认证失败",
        AUTH_SUCCESSED: "认证成功",
      },
      signStatus: {
        null: "",
        NOT_SIGNED: "未签约",
        SIGNED: "已签约",
      },
      approveStatus: {
        null: "",
        NOT_APPROVED: "未审批",
        PASSED: "审批通过",
        NOT_PASSED: "审批未通过",
      },
      tabForm: "",
      signHtml: "",
      settlementsTabData: [], // 结算信息列表
      productsTabData: [], // 结算周期列表
      accountType: {
        COMPANY: "企业",
        PERSONAL: "个人",
      },
      settleType: {
        AUTO: "自动结算",
        MANUAL: "手工结算",
        MANUAL_INSTANT: "手工即时结算",
      },
      cycleType: {
        WORK_DAY: "工作日",
        NATURE_DAY: "自然日",
        MONTH_DAY: "月结日",
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    //获取详情
    getDetail() {
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.detail = res.resultData.mchDetail;
          this.settlementsTabData = res.resultData.settlements;
          this.productsTabData = res.resultData.products;

          if(res.resultData.thirdAccounts.length>0){
            this.tabData = res.resultData.thirdAccounts[0];
          }  

        }
      });
    },
    //同步
    cloneStatus(thirdAccountId, syncType) {
      let data = {
        syncType,
      };
      getThirdaccounts(this.$route.query.id, thirdAccountId, data).then(
        (res) => {
          if (res.resultStatus) {
            this.$message.success("同步请求成功");
          }
        }
      );
    },
    // 签约
    setSign(thirdAccountId) {
      setThirdaccountsSign(this.$route.query.id, thirdAccountId).then((res) => {
        if (res.resultStatus) {
          this.$message.success("签约请求成功");
        }
      });
    },
    //查询签约内容
    setSignC(thirdAccountId) {
      setThirdaccountsSignC(this.$route.query.id, thirdAccountId).then(
        (res) => {
          if (res.resultStatus) {
            this.signHtml = res.resultData;
          }
        }
      );
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    //同步展示
    showTab(value) {
      this.tabForm = value;
      this.newShopShow = true;
    },
    // 跳转页面
    toUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}


.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .bt {
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 12px;
  color: #333333;
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 240px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.overAccount_ul .lianjie {
  margin-left: 16px;
  font-size: 12px;
  color: #2d77ff;
  cursor: pointer;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}


</style>
